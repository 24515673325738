import './App.css';

const chart = [
  {bmi: 20, level: 'pre-chub', years: 1},
  {bmi: 40, level: 'chub', years: 2},
  {bmi: 60, level: 'superchub', years: 3},
  {bmi: 80, level: 'megachub', years: 4},
];

const prec = 1e2;
const trunc = (x) => Math.ceil(x * prec) / prec;
const inc = (yrs) => yrs && trunc(20 / (yrs * 50));

const preview = () => {
  window.location = '/preview';
};

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>
          gigachub club
        </h1>
        <p>
          A 10-year program for gay men<br />
          wanting to be fatter.<br />
          Much fatter.
        </p>
        <table className="BMI-Chart">
          <thead><tr>
            <th>BMI</th>
            <th>level</th>
            <th>yrs</th>
            <th>BMI/wk</th>
          </tr></thead>
          <tbody>
            {chart.map(({bmi, level, years}) => (
              <tr key={bmi}>
                <td className="right">{bmi}</td>
                <td className="left">{level}</td>
                <td className="left">{years}</td>
                <td className="left">+{inc(years)}</td>
              </tr>
            ))}
            <tr>
              <td className="right">100</td>
              <td colSpan="3"><b>G I G A C H U B</b></td>
            </tr>
          </tbody>
        </table>
        <p><button
          style={{"fontSize": "100%"}}
          onClick={preview}
        >preview</button></p>
      </header>
    </div>
  );
}

export default App;
