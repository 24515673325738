import './App.css';
import {gnorm, round} from './calc';
import {useState} from 'react';

const calculate = (set_gs) => (e) => {
  e.preventDefault();
  e.stopPropagation();
  const ft = Number(document.getElementById('ft').value);
  const inches = Number(document.getElementById('in').value);
  const lbs = Number(document.getElementById('lbs').value);
  const yr = Number(document.getElementById('yrs').value);
  const act = Number(document.getElementById('act').value);
  const args = {ft, 'in': inches, lbs, yr, act};
  // console.log(args);
  // console.table(gs);
  const gs = round(gnorm(args));
  set_gs(gs);
};

function Preview() {
  const [gs, set_gs] = useState([]);
  console.log()
  return (
    <div className="App">
      <header className="App-header">
        <p>Enter your stats to receive a preview of your plan to become a GigaChub.</p>
        <form className="form" id="form" onSubmit={calculate}>
          <table>
            <tbody><tr>
                <td className="right">Height</td>
                <td>
                  <input id="ft" size="4" /> feet <input id="in" size="4" /> inches
                </td>
            </tr>
            <tr>
                <td className="right">Weight</td>
                <td><input size="4" id="lbs" /> pounds</td>
            </tr>
            </tbody>
          </table>
          {/* age doesn't change the formula by much. */}
          <input hidden={true} readOnly={true} id="yrs" value="20" />
          {/* activity level adds complexity so let's just assume 2 for simplicity. */}
          <input hidden={true} readOnly={true} id="act" value="2" />
          <button
            style={{"fontSize": "100%"}}
            onClick={calculate(set_gs)}
          >Calculate</button>
        </form>
        <br />
        {gs.length <= 1 ? '' : <h2>Become a GigaChub by {gs[gs.length - 1].year}</h2>}
        {gs.length !== 1 ? '' : <h2>You're already a GigaChub! Nice.</h2>}
        <table className="plan"><thead>
            <tr>{Object.keys((gs && gs[0]) || {}).map((k) => <th key={k}>{k}</th>)}</tr>
        </thead>
        <tbody>{gs.map((g, i) => <tr key={i}>{Object.keys(g).map((k) =>
            <td key={[i, k]}>{g[k]}</td>
        )}</tr>)}</tbody>
        </table>
        {gs.length === 0 ? '' : <p>Stay tuned for more.</p>}
      </header>
    </div>
  );
};
export default Preview;
