const cmPerM = 100;
const cmPerInch = 2.54;
const inchPerFt = 12;
const lbsPerKg = 2.20462;
const msPerS = 1000;
const sPerMin = 60;
const minPerHr = 60;
const hrPerDay = 24;
const dayPerYr = 365.24;
const calsPerLb = 3500;
const dailyCalsPerWeeklyLb = calsPerLb / 7;
const wksPerYr = 52;

const to_cm = (height) => {
  let cm = 0;
  cm += (height.ft || 0) * inchPerFt * cmPerInch;
  cm += (height.in || 0) * cmPerInch;
  cm += (height.m || 0) * cmPerM;
  cm += (height.cm || 0);
  return {cm};
};

const to_kg = (weight) => {
  let kg = 0;
  kg += (weight.lbs || 0) / lbsPerKg;
  kg += (weight.kg || 0);
  return {kg};
};

const to_lbs = (weight) => {
  let lbs = 0;
  lbs += (weight.lbs || 0);
  lbs += (weight.kg || 0) * lbsPerKg;
  return {lbs};
};

const to_bmi = ({cm, kg}) => {
  const m = cm / cmPerM;
  return {bmi: kg / (m**2)};
};

const from_bmi = ({cm, bmi}) => {
  const m = cm / cmPerM;
  return {kg: bmi * (m**2)};
};

const mstoyr = (ms) => {
  return ms / msPerS / sPerMin / minPerHr / hrPerDay / dayPerYr;
};
const to_yr = (obj, now_) => {
  if (obj.yr) return {yr: obj.yr};
  const {y,m,d} = obj;
  const now = now_ || new Date();
  const then = new Date(`${y}-${m}-${d}`);
  return {yr: Math.floor(mstoyr(now - then))};
};

const to_bmr = ({cm, kg, yr}) => {
  // Mifflin-St Jeor Equation, male
  return {bmr: 10*kg + 6.25*cm - 5*yr + 5};
};

const to_abmr = ({bmr, act}) => {
  return {abmr: bmr * (1+0.2*act)};
};

const norm = (data) => {
  const {cm} = to_cm(data);
  const {kg} = to_kg(data);
  const {yr} = to_yr(data);
  const {act} = data;
  return {cm, kg, yr, act};
};

const next = ({bmi}) => {
  if (bmi < 20) return 20;
  if (bmi < 40) return 40;
  if (bmi < 60) return 60;
  if (bmi < 80) return 80;
  if (bmi < 100) return 100;
};

const goals_0 = ({cm, kg, yr, act}, opts) => {
  const {next_bmi_, by_, now_} = opts;
  const start = to_lbs({kg});
  const {bmi} = to_bmi({cm, kg});
  const {bmr} = to_bmr({cm, kg, yr});
  const {abmr} = to_abmr({bmr, act});
  const next_bmi = next_bmi_ || next({bmi});
  const {lbs} = to_lbs(from_bmi({bmi: next_bmi, cm}));
  const gain = lbs - start.lbs;
  const now = now_ || new Date();
  const by = by_ || (() => {
    const d = new Date(now);
    d.setMonth(11);
    d.setDate(31);
    return d;
  })();
  const yrsToGo = mstoyr(by - now);
  const lbsPerDay = gain / yrsToGo / dayPerYr;
  const cals = abmr + lbsPerDay * calsPerLb;
  return ({lbs, cals, by});
};
void(goals_0);

const bmiPerWeek = ({bmi}) => {
  if (bmi < 40) return 0.4;
  if (bmi < 60) return 0.2;
  if (bmi < 80) return 0.14;
  return 0.1; 
};

const nextBmi = (bmi) => {
  return [
    40,
    50, 60,
    67, 74, 80,
    85, 90, 95, 100,
  ].find((b) => bmi < b);
};

const goal = ({cm, bmi, yr, act}, weeklyBmi_) => {
  const {kg} = from_bmi({cm, bmi});
  const {lbs} = to_lbs({kg});
  const weeklyBmi = weeklyBmi_ || bmiPerWeek({bmi});
  const weeklyGain = to_lbs(from_bmi({cm, bmi: weeklyBmi}));
  const {bmr} = to_bmr({cm, kg, yr});
  const {abmr} = to_abmr({bmr, act});
  const cals = abmr + weeklyGain.lbs * dailyCalsPerWeeklyLb
  return {bmi, lbs, cals};
};

const goal0 = ({cm, bmi, yr, act}, now_) => {
  const now = now_ || new Date();
  const nye = (() => {
    const d = new Date(now);
    d.setMonth(11);
    d.setDate(31);
    return d;
  })();
  const yrs = mstoyr(nye-now);
  const wks = yrs * wksPerYr;
  const stdWeeklyBmi = bmiPerWeek({bmi});
  const next = nextBmi(bmi);
  const nextWeeklyBmi = bmiPerWeek({bmi: next});
  const minWeeklyBmi = (next - bmi) / wks;
  if (minWeeklyBmi > stdWeeklyBmi) {
    const weeklyBmi_ = Math.max((next - bmi) / (wks+52), nextWeeklyBmi);
    const next0 = bmi + wks*weeklyBmi_;
    const g0 = goal({cm, bmi, yr, act}, weeklyBmi_);
    const g1 = goal({cm, bmi: next0, yr, act}, weeklyBmi_);
    return [g0, g1];
  } else {
    const weeklyBmi_ = Math.max((next - bmi) / wks, nextWeeklyBmi);
    return [goal({cm, bmi, yr, act}, weeklyBmi_), null];
  }
};

const goals = ({cm, kg, yr, act}) => {
  let {bmi} = to_bmi({cm, kg});
  const [g0, g1] = goal0({cm, bmi, yr, act});
  let year = (new Date()).getFullYear();
  const gs = [{year, ...g0}];
  if (g1) { yr++; year++; gs.push({year, ...g1}); }
  // eslint-disable-next-line
  while (bmi = nextBmi(bmi)) {
    yr++; year++;
    // if (act < 3) act = act + 1;
    gs.push({year, ...goal({cm, bmi, yr, act})});
  }
  return gs;
};
const gnorm = (args) => {
  const nargs = norm(args);
  return goals(nargs);
};
const ceiln = (x, n) => {
  return Math.ceil(x / n) * n;
};
const round = (gs) => gs.map((g) => {
  const obj = {};
  for (const k in g) {
    obj[k] = Math.round(g[k]);
  }
  obj['cals'] = ceiln(obj['cals'], 100);
  obj['lbs'] = ceiln(obj['lbs'], 5);
  return obj;
});
// data = {ft: 5, in: 11, lbs: 230, yr: 35, act: 2};
// ndata = norm(data);
// console.log(ndata);
// console.log('from', data);
// console.table(round(goals(ndata)));

export {gnorm, round};